import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';

import Article from '../components/article';
import Layout from '../components/layout';
import SEO from '../components/seo';
import { store } from '../context/store';

const ArticlePage = ({ location }) => {
  const globalState = useContext(store);
  const { todaysNewsList } = globalState.state;

  const [activeArticle, setActiveArticle] = useState([]);
  const [timeToRead, setTimeToRead] = useState(0);
  const [loading, setLoading] = useState(true);

  const setTodaysNewsList = () => {
    if (todaysNewsList.length) {
      const url = location.search.replace('?page=', '');
      getArticle(url);
    }
  };

  useEffect(setTodaysNewsList, [globalState.state.todaysNewsList, location.search]);

  const getWordCount = (article) => {
    return article[0].body.reduce((total, paragraph) => {
      if (paragraph.type === 'text') {
        const paragraphArr = paragraph.body.split(' ');
        return total + paragraphArr.length;
      } else {
        return total;
      }
    }, 0);
  }

  function getArticle(url) {
    setLoading(true);
    const { password, localStorage } = globalState.state;

    const cachedNewsArticle = localStorage.getItem(url);

    if (cachedNewsArticle) {
      const article = JSON.parse(cachedNewsArticle);
      const wordCount = getWordCount(article);

      setLoading(false);
      setActiveArticle(article);
      setTimeToRead(Math.ceil(wordCount / 225));
    } else {
      axios
        .get(`/.netlify/functions/economist?key=${password}&page=${url}`)
        .then(res => {
          const wordCount = getWordCount(res.data);

          setLoading(false);
          setActiveArticle(res.data);
          setTimeToRead(Math.ceil(wordCount / 225));
          
          window.scrollTo(0, 0);
          localStorage.setItem(url, JSON.stringify(res.data));
        })
        .catch(err => console.log('Error: ', err));
    }
  }

  return (
    <Layout loading={loading}>
      <SEO title={activeArticle.length ? activeArticle[0].headline : 'Home'} />
      {activeArticle.map(article => (
        <Article
          key={article.headline}
          article={article}
          timeToRead={timeToRead} 
        />
      ))}
    </Layout>
  );
}

export default ArticlePage;
