import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';

import { Consumer } from '../context/store';

const Article = ({ article, timeToRead }) => {
  const renderLink = url => {
    console.log('Incoming Link', url);

    if (url.indexOf('https://economist.com') === 0) {
      url = url.replace('https://economist.com', '');
    } else if (url.indexOf('http://economist.com') === 0) {
      url = url.replace('http://economist.com', '');
    } else if (url.indexOf('https://www.economist.com') === 0) {
      url = url.replace('https://www.economist.com', '');
    } else if (url.indexOf('http://www.economist.com') === 0) {
      url = url.replace('http://www.economist.com', '');
    } else if (url.indexOf('https://') === 0 || url.indexOf('http://') === 0) {
      return (
        <a href={url} target="_blank" rel="noopener noreferrer">
          article
        </a>
      );
    }

    return <Link to={`/article?page=${url}`}>article</Link>;
  };

  const renderParagraphText = (paragraph, index, value) => {
    if (paragraph.links[0] && paragraph.links[0].linkText === 'article') {
      const link = paragraph.links[0];

      const articleIndex = paragraph.body.indexOf('article');
      return (
        <p
          key={`paragraph-${index}`}
          style={{ fontSize: `${value.state.textSize}px` }}
        >
          {paragraph.body.slice(0, articleIndex)} {renderLink(link.linkUrl)}
          {paragraph.body.slice(articleIndex + 7)}
        </p>
      );
    }

    return (
      <p
        key={`paragraph-${index}`}
        style={{ fontSize: `${value.state.textSize}px` }}
      >
        {paragraph.body}
      </p>
    );
  };

  console.log('BODY', article.body);

  return (
    <Consumer>
      {value => (
        <div className="article" style={{ maxWidth: value.state.maxWidth }}>
          {article.mainImage && (
            <div className="article__image-container">
              <img src={article.mainImage} alt="Banner" />
            </div>
          )}
          <small className="article__subheadline">{article.subheadline}</small>
          <h2 className="article__headline">{article.headline}</h2>
          <p className="article__date">
            <em>
              {article.date} | {timeToRead}min Read
            </em>
          </p>
          <div className="article__body">
            {article.body.map((paragraph, index) => {
              switch (paragraph.type) {
                case 'image':
                  return (
                    <img
                      key={`paragraph-${index}`}
                      src={paragraph.src}
                      alt={`Article Figure ${index}`}
                    />
                  );

                case 'iframe':
                  return (
                    <div
                      key={`paragraph-${index}`}
                      className={`iframe-container ${paragraph.class}`}
                    >
                      <iframe
                        title={`Article iFrame ${index}`}
                        src={paragraph.src}
                      />
                    </div>
                  );

                default:
                  return renderParagraphText(paragraph, index, value);
              }
            })}
          </div>
        </div>
      )}
    </Consumer>
  );
};

export default Article;

Article.propTypes = {
  article: PropTypes.shape({
    headline: PropTypes.string,
    date: PropTypes.string,
    mainImage: PropTypes.string,
    subheadline: PropTypes.string,
    paragraph: PropTypes.arrayOf([PropTypes.string]),
  }).isRequired,
};
